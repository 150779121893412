<template>
<div class="banner">
    <section class="wh_content">
        <div class="wh_swiper wh_swiper_298">
            <div class="wh_slide" style="min-height: 65px;"><img src="https://gamemania-image.s3.eu-central-1.amazonaws.com/resource/banner/PmcxfxAijSE6qsJfzAIn5cEsHyki3AZKzZR2QCER.jpeg" alt="banner"></div>
        </div>
        <div class="wh_indicator" style="z-index: 10;">
            <div class="wh_indicator_item wh_show_bgcolor"></div>
        </div>
    </section>
</div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>

<style lang="scss" scoped>
    .banner, .banner img {
        width: 9.44444444rem;
        height: 1.85185185rem;
    }
    .banner {
        position: relative;
        z-index: 2;
        margin: 0.27777778rem auto 0.08333333rem;
        border-radius: 0.09259259rem;
        overflow: hidden;
    }
    .wh_content {
        position: relative;
        z-index: 1;
        overflow: hidden;
        width: 100%;
    }
    .wh_swiper {
        width: 100%;
        display: flex;
        transition-duration: 0s linear;
    }    
    .wh_slide {
        width: 100%;
        flex-shrink: 0;
        z-index: 10;
        min-height: 100px;
    }
    .wh_slide {
        min-height: 65px!important;
    }    
    
</style>
