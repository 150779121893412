<template>
<div class="tabbar" style="display: flex; justify-content: space-between; align-items: center;">
    <div class="box">
        <div class="icon icon0">
            <!---->
        </div>
        <div class="name">Get Coins</div>
    </div>
    <div class="box">
        <div class="icon icon1">
            <div class="newIcon"></div>
        </div>
        <div class="name">Coins Spin</div>
    </div>
    <div class="box">
        <div class="icon icon2">
            <!---->
        </div>
        <div class="name">Get Cash</div>
    </div>
    <div class="box">
        <div class="icon icon3">
            <!---->
        </div>
        <div class="name">Download</div>
    </div>
    <!---->
</div>
</template>

<script>
export default {
    name: 'TopTabBar'
}
</script>

<style lang="scss" scoped>
.tabbar {
    padding: 0.38888889rem 0;
    color: hsla(0,0%,100%,.5);
    .box {
        width: 2.5rem;
    }
    .icon {
        margin: 0 auto;
        position: relative;
        .newIcon {
            width: 0.57407407rem;
            height: 0.27777778rem;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAAeCAMAAABDs11AAAABg1BMVEUAAADbL0XdN0v/fHz+eXr/fX3/fn7cMUj/gIDfQGD/fHz7cHPjP1HYKkH/fHz+d3jZLUTaLUT/fHz6cnThPVDXKUL/fHz8dHfYK0P/fHzbMUfgOk7/fX3/eXnfNkzYK0X/gID/d3nhPE3ZK0TgPFDgPE//////fHz/e3vrUl7iP1HeN0v9eHnqT1zhPE7dNUncM0j0Y2ruWGLmSFfzYWnoS1nkRFT+eXrtVGDtVmH9dnj4bXH3am/bMEbfOUz1Z23yYGjxXmf7c3X6cXTwXGXvWmTbMUfZLUT3bHDlRlX8dXfpTVvjQVL96er5bnL+7Oz5b3P1ZWznSVjjQ1PaL0XYK0L2srf8ra/2aW797u/3wMT4lpnlVGT++fnznqbzi5PwfIT63eD3yc71oabxlp/3h4vzgIfnZHP+9vf98fL82tz609f5zdH1usDypa3uiJLsgY3rbXn84+X5naD8j5LudoDpcn/3bXHsYWz8w8XzqbH5f4PsaHP7trj1cXflXGvpWmjYDRWkAAAAJnRSTlMAh+bijIVTUwgI8/Pz8+np6eLZ2dnZv7+/qKiMdnZ2djw8PDy/v9Ojx4sAAANdSURBVDjLnZV5V1JRFMUxTRu0rMzKBivr8N5DFEHBCcQRUVRi0CBIURASJxxybPjo7XPfvT4U/+qs5XouXb+199n3nnNtVn1oePHsYXRkdvTr2Je+vv7g+NSU2z00NKjrzvmBgZgn7PVGjOa2z68/2mrq3tvHwxMTk9FFiYMen5qedoM2cY/H443HDaOnJxC48+7+dbqx3jc8PAee8TGhrvBB3SnVwxJ3OHrvNFXTdzWtu5vp6JX5YBC4m3GlzuaBM9/rctVZ9Cu73efrhnqVedH7lfl5qHskHgDe65rpuNK2a8CFOsyPSHz8mnrMjC6i8JkZv9Rv1Ox2O8zL6GTvQSt55w3zDjY/4080iczrNdA+9H50dHSxeDI7+rtQKPT1b6LWUMenlePjU5jPVCoeqGeKxYyD8dATzv8Nawv1JaKFSah/I6K+4ALJ+r5NtAP1c6J9qJfxF/Tu9ydCncAfaUodOK3cgu8SbQHfIlo2DCNL9NPhAh5Kttps71mckzfx0vCIwPv7gadXuS7XiLIDsQwRHUSMP/jEoQ7zoWSXrUFj65oP14ZxOpu18I1pRKfrQxC8jO0TKmPgkxPRJRKhZJ2tHbRIXuJUsMwDd/O55Yj2PMtE+Bi7aAEHZ+IvbU+ZFefOeBltWuq5NGpT10Gexw4ol6UdYx0BOkDj4JLJ57YHDHN2Qn0FKR8KdeAyOd25SrT9l2h3nVJGCh0IdSQfagFu5+5l8isXWUodKvXSD1Red54SpfYQeB4/+NXB6iK6FpiHOKubePQX8lXqG2re8fs6lTxF/lBa3HnGYb5dWOdzF+YXJ+GO1UV0amTSRCC9cfG/PHA+dxFdA8Oqd+AjbF2pq3nPCywc3+Fv0WEdHK6NUNdk75j3LVIHV17mWtOdFYF5vQiAsoZUDyWTXbi06tZJ9dkCyeis6J0loiXMO9+8ciCgkm/lkWHrwE11nvezGnyep4YHNofjE7hIvlMM7P/OOwYW1agmbm4S2+ZErUq16xC8WtTxSMRclea8N1nLSqlbq9LatNaqjKtVyfNeZ61KoX6r+cEb5pW6v6NqUYvo5q6tymDNqgxXrUpXXc0zAVqpA5fmh1TvoOOmefFM1D5Sc7W9i3lX5sO1j9TNJxLJK/O37vnmtk/VT+Q/WBp6BJ2Rx8wAAAAASUVORK5CYII=);
            background-size: cover;
            position: absolute;
            right: -0.27777778rem;
            top: 0;
        }
    }
    .icon0 {
        background-image: url(../assets/icon.png);
        background-position: -1.11111111rem -2.03703704rem;
        width: 1rem;
        height: 1rem;
        background-size: 5.66666667rem 5.37037037rem;
    }
    .icon1, .icon2 {
        background-image: url(../assets/icon.png);
        width: 1.01851852rem;
        background-size: 5.66666667rem 5.37037037rem;
    }
    .icon1 {
        background-position: -2rem -0.52777778rem;
        height: 1.0462963rem;
    }
    .icon2 {
        background-position: 0 -2.03703704rem;
        height: 1.01851852rem;
    }
    .icon3, .icon4 {
        background-image: url(../assets/icon.png);
        background-size: 5.66666667rem 5.37037037rem;
    }
    .icon3 {
        background-position: -2.2037037rem -2.03703704rem;
        width: 1.01851852rem;
        height: 0.96296296rem;
    }
    .name {
        padding: 0.11111111rem 0 0;
        text-align: center;
        font-size: .27777778rem;
    }    
}


</style>
