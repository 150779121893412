<template>
<div class="scrolling">
    <div class="speaker"></div>
    <div class="display">
        <div class="scrollingItem bouncein" style="display: flex; justify-content: center; align-items: center;">Congratulations Jimmy Dady for winning Ksh1223</div>
    </div>
</div>
</template>

<script>
export default {
    name: 'ScrollingBar'
}
</script>

<style lang="scss" scoped>
.scrolling {
    position: relative;
    width: 9.44444444rem;
    height: 0.40740741rem;
    line-height: .40740741rem;
    color: #fff;
    font-size: .27777778rem;
    background-color: #2a2b40;
    overflow: hidden;
    border-radius: 0.2037037rem;
    margin: 0 auto;

    .speaker {
        position: absolute;
        top: 0.05555556rem;
        left: 0.09259259rem;
        z-index: 2;
        background-image: url(../assets/icon.png);
        background-position: -2rem -1.66666667rem;
        width: 0.25925926rem;
        height: 0.25925926rem;
        background-size: 5.66666667rem 5.37037037rem;
    }

    .display {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0.27777778rem;
        overflow: hidden;
    }

    .scrollingItem {
        width: 9rem;
        height: 0.40740741rem;
        white-space: nowrap;
        padding: 0 0 0 0.09259259rem;
    }
}

.bouncein {
    animation: bouncein 5s linear infinite;
}

@keyframes bouncein {
    0% {
        transform: translate3d(0, 100%, 0)
    }

    20% {
        transform: translateZ(0)
    }

    90% {
        transform: translateZ(0)
    }

    to {
        transform: translate3d(0, -120%, 0)
    }
}
</style>
