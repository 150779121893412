<template>
<div class="online">
    <p class="word">
        Enter amount, click [Top Up Now] button and input
        Mpesa PIN to authorize transaction
    </p>
    <p class="word itemTitle">
        Amount in Kshs:
    </p>
    <div class="box"><input type="number" placeholder="Please enter amount"></div>
    <div class="word itemTitle" style="display: flex; justify-content: space-between; align-items: center;">
        <div>Deposit:</div>
        <div class="clear" style="display: flex; justify-content: center; align-items: center;">
            Clear
        </div>
    </div>
    <div class="list list1" style="display: flex; justify-content: space-between; align-items: center;">
        <div class="list1Item">
            <div class="topNumber">+48</div>
            <div class="bottomNumber">Get 50</div>
        </div>
        <div class="list1Item">
            <div class="topNumber">+95</div>
            <div class="bottomNumber">Get 100</div>
        </div>
        <div class="list1Item">
            <div class="topNumber">+477</div>
            <div class="bottomNumber">Get 500</div>
        </div>
        <div class="list1Item">
            <div class="topNumber">+977</div>
            <div class="bottomNumber">Get 1000</div>
        </div>
        <!---->
    </div>
    <div class="topUp btnStyle grayWhite" style="display: flex; justify-content: center; align-items: center;">
        Top Up Now
    </div>
    <div class="word">
        Deposit an amount between (95-99,or 250-70,000) from your mpesa account to your Gamemania main account. all safaricom mpesa charges incurred on all qualifying deposits as indicated above will be credited automatically on top of your initial deposit. See table below for
        <span class="more">More&gt;</span></div>
</div>
</template>

<script>
export default {
    name: 'PayOnline'
};
</script>

<style scoped>
.online {
    padding: 0 0.27777778rem;
}

.online .word {
    font-size: .30555556rem;
    line-height: .44444444rem;
    margin-top: 0.5rem;
    color: rgba(42, 43, 64, .8);
}

.online .itemTitle {
    color: #2a2b40;
    font-size: .38888889rem;
}

.online .box {
    margin-top: 0.44444444rem;
    border-radius: 0.09259259rem;
    border: 1px solid #b3b3b3;
}

.online .box input {
    width: 9.38888889rem;
    height: 1.38888889rem;
    outline: none;
    border: none;
    color: #2a2b40;
    font-size: .66666667rem;
    text-align: center;
    padding: 0;
}

.online .word {
    font-size: .30555556rem;
    line-height: .44444444rem;
    margin-top: 0.5rem;
    color: rgba(42,43,64,.8);
}

.online .word .more {
    color: #e505ff;
}

.online .word .clear {
    width: 1.38888889rem;
    height: 0.69444444rem;
    font-size: .30555556rem;
    border-radius: 0.36111111rem;
    border: 1px solid #b3b3b3;
}

.online .list {
    margin-top: 0.33333333rem;
}

.online .list .list1Item {
    width: 2.25rem;
    height: 1.58333333rem;
    background-color: rgba(229, 5, 255, .1);
    border-radius: 0.09259259rem;
    box-sizing: border-box;
    border: 1px solid #f3a2fd;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.online .list .list1Item .topNumber {
    color: #e71fff;
    font-weight: 700;
    font-size: .47222222rem;
}

.online .list .list1Item .bottomNumber {
    padding-top: 0.13888889rem;
    font-size: .30555556rem;
}

.online .topUp {
    width: 8.55555556rem;
    height: 1.25rem;
    border-radius: 0.63888889rem;
    margin: 0.69444444rem auto 0;
}

.btnStyle {
    color: #fff;
    font-size: .48148148rem;
    font-weight: 900;
    background: #d8d8d8 linear-gradient(90deg, #e601fe, #7902ff);
}

.btnStyle.grayWhite {
    background: #e0e0e0;
    color: #fff;
}



</style>
