<template>
<div id="app">
    <transition :name="$store.state.pageTransition.name" :mode="$store.state.pageTransition.mode" v-on:after-enter="afterEnter" v-on:after-leave="afterLeave">
        <router-view />
    </transition>
</div>
</template>

<script>
import Vue from 'vue';
import Store from "./store/index";
import Vant from 'vant';
// import { Icon } from 'vant';
import 'vant/lib/index.css';
// import 'vant/lib/icon/local.css'

Vue.use(Vant);

export default {
    name: "App",
    components: {},
    methods: {
        afterEnter: () => {
            window.scrollTo(0, 0);
        },
        afterLeave: () => {
            Store.commit("setPageTransition", "default");
        },
        onResize() {
            var windowHeight = window.innerHeight;
            let frontSize = (windowHeight / 16.83).toFixed(2);
            document.getElementsByTagName('html')[0].setAttribute('style', 'font-size: ' + frontSize + 'px;');
        }
    },
    beforeCreate() {
        this.$nextTick().then(() => {
            document.body.style = "margin: 0px auto; font-size: 12px;";
            this.onResize();
        })
    },
    mounted() {
        window.addEventListener('resize', this.onResize);
        var queryToken = this.$route.query.token;
        var token = queryToken && queryToken != '' ? queryToken : '726fc216-6132-4663-8229-d9f2804c79fe';
        localStorage.token = token;
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
        localStorage.removeItem('token');
    }
};
</script>

<style lang="scss">
#app {
    font-family: Helvetica, Avenir, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 10rem;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    color: #444;
    font-size: .33333333rem;
    background-color: #1e1f2d;
}

$duration: 0.5s;

// .transition {
//     overflow: hidden;
//     min-height: 100%
// }

.router-view,
.router-view-back {

    &-enter-active,
    &-leave-active {
        position: absolute;
        width: 100%;
        background: #1e1f2d;
        min-height: 100vh;
        top: 0;
        bottom: 0;
    }
}

// router view
.router-view-enter-active {
    transition: transform $duration ease-in-out;
    z-index: 2;
    transform: translateX(100%);
}

.router-view-enter-to {
    z-index: 2;
    transform: translateX(0%);
}

.router-view-leave-active {
    z-index: -1;
}

.router-view-leave-to {
    z-index: -1;
}

// router view back

.router-view-back-leave-active {
    transition: transform $duration ease-in-out;
    z-index: 2;
    transform: translateX(0%);
}

.router-view-back-leave-to {
    z-index: 2;
    transform: translateX(100%);
}

::-webkit-scrollbar {
    display: none;
}

</style>
