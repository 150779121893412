<template>
    <div class="open-app-container">
        <div class="delete-box">
            <van-icon name="cross" size=".5rem" />
        </div>
        <div class="icon"></div> 
        <div class="center">
            <div class="name">Tega Game</div> 
            <div class="text">Download our App for a Better Experience!</div>
        </div> 
        <div class="getbox" style="display: flex; justify-content: center; align-items: center;">Download</div>
    </div>
</template>

<script>
export default {
  name: 'TopBanner'
}
</script>

<style scoped>
.open-app-container {
    height: 1.2962963rem;
    background: #1e1f2d -webkit-gradient(linear, left top, right top, from(#ba00ff), to(#7902ff));
    background: #1e1f2d -webkit-linear-gradient(left, #ba00ff, #7902ff);
    background: #1e1f2d linear-gradient(90deg, #ba00ff, #7902ff);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    padding: 0 0.27778rem;
}

.open-app-container .delete-box {
    width: 0.55556rem;
    margin-right: 0.27778rem;
    height: 1.11111rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.open-app-container .icon {
    background-image: url(../assets/icon.png);
    background-position: 0 -3.14814815rem;
    width: 0.88888889rem;
    height: 0.88888889rem;
    background-size: 5.66666667rem 5.37037037rem;
    margin: 0 0.18518519rem 0 0;
}

.open-app-container .center {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.open-app-container .center {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.open-app-container .center .name {
    font-size: .40740741rem;
    font-weight: 900;
    font-style: italic;
}

.open-app-container .center .text {
    font-size: .27777778rem;
    font-weight: 300;
}

.open-app-container .getbox {
    width: 1.83333333rem;
    height: 0.64814815rem;
    background: #fff;
    border-radius: 0.09259259rem;
    color: #8102ff;
    font-weight: 700;
    font-size: .31481481rem;
}

</style>