<template>
<div>
    <header style="display: flex; justify-content: space-between; align-items: center;">
        <div class="left"><van-icon name="arrow-left" size=".5rem" @click="goBack()" /></div>
        <div class="title">{{title}}</div>
        <div class="right"></div>
    </header>
</div>
</template>

<script>
export default {
    name: 'SubNavBar',
    props: {
        title: String
    },
    methods: {
        goBack() {
            this.$store.commit("setPageTransition", "back");
            this.$router.go(-1);
        }
    }
};
</script>

<style lang="scss" scoped>
header {
    height: 1.25rem;
    background-color: #35314f;
    padding: 0 0.27777778rem;

    .left .icon-back {
        font-weight: 600;
        font-size: .5rem;
    }

    .title {
        font-size: .41666667rem;
        text-align: center;
        white-space: nowrap;
    }

    div {
        min-width: 0.55555556rem;
        color: #fff;
        flex: 1;
    }
}


</style>
