<template>
<div class="pageFooter">
    <div class="name">Tega Game</div>
    <div class="paybill"><span class="title">M-PESA Paybill:</span> <span class="number">290077</span></div>
    <div class="contactBox" style="display: flex; justify-content: space-between; align-items: center;">
        <div class="item">ABOUT US</div>
        <div class="item">CONTACT US</div>
        <div class="item">HOW TO PLAY</div>
    </div>
    <div class="customerService">
        <p class="article">
            SlotGen will respond to all of your requests.Contact us using one of the methods listed below.
        </p>
        <div class="customer" style="display: flex; justify-content: space-between; align-items: center;">
            <div class="left"><span class="name">Contact us online:</span>
                <div class="communion" style="display: flex; justify-content: center; align-items: center;"></div>
            </div>
            <div class="right"><span class="name">Follow us on:</span>
                <div class="liveChat"><a href="#" target="_blank">
                        <div class="facebook"></div>
                    </a> <a href="#" target="_blank">
                        <div class="twitter"></div>
                    </a></div>
            </div>
        </div>
        <div class="customer" style="display: flex; justify-content: center; align-items: center;">
            <div class="right" style="justify-content: center;">
                <div class="email"></div>
                <div class="liveChat">
                    service@tegagame.com
                </div>
            </div>
        </div>
    </div>
    <div class="age" style="display: flex; justify-content: center; align-items: center;">18+</div>
    <p class="article">
        This product is intended for use by those 18 or older and is for amusement purposes only.
        <br data-v-7144c50c="">
        Practice or success at social casino gaming does not imply future success at real money gambling and gaming.
    </p>
    <div class="licence">LICENCE</div>
    <p class="article">
        SlotGen is licensed by BCLB under the Betting,Lotteries and Gaming Act, Cap 131, Laws of Kenya under license number :0000154
    </p>
</div>
</template>

<script>
export default {
    name: 'PageFooter'
}
</script>

<style lang="scss" scoped>
.pageFooter {
    padding: 0.60185185rem 0 2rem;
    color: #fff;
    font-size: .33333333rem;

    .name {
        font-size: .46296296rem;
        font-weight: 900;
        text-align: center;
    }

    .paybill {
        padding: 0.27777778rem 0 0;
        text-align: center;
        font-size: .27777778rem;
        color: hsla(0, 0%, 100%, .6);

        .number[data-v-7144c50c] {
            padding: 0 0 0 0.18518519rem;
            color: #fff;
            font-size: .37037037rem;
            font-weight: 900;
        }
    }

    .contactBox {
        position: relative;
        padding: .52777778rem 0 0;
        font-size: .27777778rem;
        font-weight: 900;

        .item {
            flex: 1;
            text-align: center;
        }

        :before {
            content: "";
            position: absolute;
            left: 3.35185185rem;
            top: .59259259rem;
            width: 1px;
            height: .18518519rem;
            background: #fff
        }

        :after {
            content: "";
            position: absolute;
            right: 3.35185185rem;
            top: .59259259rem;
            width: 1px;
            height: .18518519rem;
            background: #fff
        }
    }

    .customerService {
        padding: 0.55555556rem 0 0;

        .customer {
            padding: 0.27777778rem;

            .left {
                box-sizing: border-box;
                width: 5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;

                .communion {
                    margin: 0 0 0 0.27777778rem;
                    background-image: url(../assets/icon.png);
                    background-position: -1.91666667rem -3.14814815rem;
                    width: 0.84259259rem;
                    height: 0.84259259rem;
                    background-size: 5.66666667rem 5.37037037rem;
                }
            }

            .right {
                width: 5rem;
                display: flex;
                align-items: center;

                .liveChat {
                    display: flex;
                    justify-content: flex-start;
                    font-size: .27777778rem;
                    font-weight: 900;

                    .facebook, .twitter {
                        margin: 0 0 0 0.27777778rem;
                        background-image: url(../assets/icon.png);
                        background-size: 5.66666667rem 5.37037037rem;
                    }

                    .facebook {
                        background-position: -0.98148148rem -3.14814815rem;
                        width: 0.84259259rem;
                        height: 0.84259259rem;
                    }

                    .twitter {
                        background-position: -2.85185185rem -3.14814815rem;
                        width: 0.83333333rem;
                        height: 0.83333333rem;
                    }

                }

                .email {
                    flex-shrink: 0;
                    margin: 0 .18518519rem 0 0;
                    background-image: url(../assets/icon.png);
                    background-position: -5.03703704rem -4.24074074rem;
                    width: 0.48148148rem;
                    height: 0.48148148rem;
                    background-size: 5.66666667rem 5.37037037rem;
                }
            }

            .name {
                font-size: .27777778rem;
                color: hsla(0, 0%, 100%, .6);
            }

        }
    }

    .article {
        width: 8.67592593rem;
        margin: 0 auto;
        font-size: .27777778rem;
        color: hsla(0, 0%, 100%, .6);
        text-align: center;
    }

    .age {
        width: 0.59259259rem;
        height: 0.59259259rem;
        margin: 0.92592593rem auto 0.25rem;
        border: 1px solid #979797;
        border-radius: 0.09259259rem;
        color: #979797;
        font-size: .27777778rem;
        font-weight: 900;
    }

    .licence {
        padding: 0.5rem 0 0.25rem;
        color: hsla(0,0%,100%,.6);
        font-weight: 900;
        font-size: .27777778rem;
        text-align: center;
    }

    a:active,
    a:hover,
    a:link,
    a:visited {
        text-decoration: none;
        color: #fff
    }
}
</style>
