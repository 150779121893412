<template>
<div>
    <SubNavBar title="Withdraw" />
    <div class="comming-soon" style="display: flex; justify-content: center; align-items: center;">COMMING SOON</div>
</div>
</template>

<script>
import SubNavBar from '@/components/SubNavBar.vue'
export default {
    name: 'Withdraw',
    components: {
        SubNavBar
    }
};
</script>

<style scoped>
.comming-soon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .6);
    color: #fff;
    font-size: .5rem;
    font-weight: 700;
}
</style>
