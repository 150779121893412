<template>
<div class="deposit">
    <SubNavBar title="Deposit" />
    <van-tabs>
        <van-tab title="Online Deposit">
          <PayOnline />
        </van-tab>
        <van-tab title="Pay Bill">
          <PayBill />
        </van-tab>
    </van-tabs>
</div>
</template>

<script>
import SubNavBar from '@/components/SubNavBar.vue'
import PayOnline from '@/components/PayOnline.vue'
import PayBill from '@/components/PayBill.vue'

export default {
    name: 'Deposit',
    components: {
        SubNavBar,
        PayBill,
        PayOnline
    }
};
</script>

<style scoped>
.deposit {
    height: 100%;
    width: 10rem;
    background-color: #fff;
}

.online {
    padding: 0 0.27777778rem;
}

.word {
    font-size: .30555556rem;
    line-height: .44444444rem;
    margin-top: 0.5rem;
    color: rgba(42, 43, 64, .8);
}


</style>
