<template>
<div>
    <TopBanner />
    <TopHeader />
    <div class="shadow"></div>
    <TopTabBar />
    <ScrollingBar />
    <MainBanner />
    <GameBox />
    <PageFooter />
</div>
</template>

<script>
import TopBanner from '@/components/TopBanner.vue'
import TopHeader from '@/components/TopHeader.vue'
import TopTabBar from '@/components/TopTabBar.vue'
import ScrollingBar from '@/components/ScrollingBar.vue'
import MainBanner from '@/components/MainBanner.vue'
import GameBox from '@/components/GameBox.vue'
import PageFooter from '@/components/PageFooter.vue'

export default {
    name: 'Home',
    components: {
        TopBanner,
        TopHeader,
        TopTabBar,
        ScrollingBar,
        MainBanner,
        GameBox,
        PageFooter
    },
}
</script>

<style lang="scss" scoped>
.shadow {
    position: absolute;
    z-index: 1;
    width: 10rem;
    height: 0.75rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, .5), transparent);
}
</style>
