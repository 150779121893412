<template>
<div class="header" style="display: flex; justify-content: space-between; align-items: center;">
    <div class="nameIcon"></div>
    <div class="right" style="display: flex; justify-content: flex-start; align-items: center;">
        <div class="sign" style="display: flex; justify-content: center; align-items: center;" @click="goSigin()">Sign In</div>
        <div class="login" style="display: flex; justify-content: center; align-items: center;">Register</div>
    </div>
</div>
</template>

<script>
export default {
    name: 'TopHeader',
    methods: {
        goSigin() {
        this.$router.push({
            name: "Login"
        });
        }
    }
}
</script>

<style scoped>
.header {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 10rem;
    height: 1.2962963rem;
    padding: 0 0 0 0.27777778rem;
    background: #2a2b40;
    color: #fff;
}

.header .nameIcon {
    background-image: url(../assets/icon.png);
    background-position: -2rem 0;
    width: 2.94444444rem;
    height: 0.43518519rem;
    background-size: 5.66666667rem 5.37037037rem;
}

.header .right {
    font-weight: 900;
    font-size: .31481481rem;
}

.header .right .sign {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 1.66666667rem;
    height: 0.64814815rem;
    border-radius: 0.37037037rem;
    border: 0.01851852rem solid #fff;
    margin: 0 0.27777778rem 0 0;
}

.header .right .login {
    width: 1.66666667rem;
    height: 0.64814815rem;
    background: -webkit-gradient(linear, left top, right top, from(#e601fe), to(#7902ff));
    background: -webkit-linear-gradient(left, #e601fe, #7902ff);
    background: linear-gradient(90deg, #e601fe, #7902ff);
    border-radius: 0.37037037rem;
    margin: 0 0.27777778rem 0 0;
}

</style>
