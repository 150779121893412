<template>
<div class="gameBox">
    <div>
        <div class="itemTitleBox">
            <div class="left" style="display: flex; justify-content: center; align-items: center;">
                <div class="itemIcon1"></div>
                <div class="itemTitle">FOR YOU</div>
            </div>
            <div class="right" style="display: flex; justify-content: center; align-items: center;"><span>More</span>
                <div class="icon"></div>
            </div>
        </div>
        <div class="topGame" style="display: flex; justify-content: space-between; align-items: center;">
            <div class="game foryou">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Premier League Star</div>
                    </div>
                    <div class="image-cover image-cover4"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4c8d10be0.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4c8d10be0.jpg.webp" lazy="loaded"></div>
                    <div class="button" style="display: flex; justify-content: center; align-items: center;">
                        <div class="play" style="display: flex; justify-content: center; align-items: center;" @click="playGame()">
                            PLAY NOW
                        </div>
                        <div class="demo" style="display: flex; justify-content: center; align-items: center;" @click="playGame()">
                            DEMO
                        </div>
                    </div>
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>549</span></div>
                    </div>
                </div>
            </div>
            <div class="game foryou">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Gold Cup</div>
                    </div>
                    <div class="image-cover image-cover4"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4c25e9113.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4c25e9113.jpg.webp" lazy="loaded"></div>
                    <div class="button" style="display: flex; justify-content: center; align-items: center;">
                        <div class="play" style="display: flex; justify-content: center; align-items: center;" @click="playGame()">
                            PLAY NOW
                        </div>
                        <div class="demo" style="display: flex; justify-content: center; align-items: center;" @click="playGame()">
                            DEMO
                        </div>
                    </div>
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>1677</span></div>
                    </div>
                </div>
            </div>
            <div class="game foryou">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Hot Spin</div>
                    </div>
                    <div class="image-cover image-cover3"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4c7c6ba91.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4c7c6ba91.jpg.webp" lazy="loaded"></div>
                    <div class="button" style="display: flex; justify-content: center; align-items: center;">
                        <div class="play" style="display: flex; justify-content: center; align-items: center;" @click="playGame()">
                            PLAY NOW
                        </div>
                        <div class="demo" style="display: flex; justify-content: center; align-items: center;" @click="playGame()">
                            DEMO
                        </div>
                    </div>
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>701</span></div>
                    </div>
                </div>
            </div>
            <div class="game foryou">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Hot Safari</div>
                    </div>
                    <div class="image-cover image-cover4"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4c5d43d59.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4c5d43d59.jpg.webp" lazy="loaded"></div>
                    <div class="button" style="display: flex; justify-content: center; align-items: center;">
                        <div class="play" style="display: flex; justify-content: center; align-items: center;" @click="playGame()">
                            PLAY NOW
                        </div>
                        <div class="demo" style="display: flex; justify-content: center; align-items: center;" @click="playGame()">
                            DEMO
                        </div>
                    </div>
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>275</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="itemTitleBox">
            <div class="left" style="display: flex; justify-content: center; align-items: center;">
                <div class="itemIcon2"></div>
                <div class="itemTitle">NEW GAMES</div>
            </div>
            <div class="right" style="display: flex; justify-content: center; align-items: center;"><span>More</span>
                <div class="icon"></div>
            </div>
        </div>
        <div class="topGame" style="display: flex; justify-content: space-between; align-items: center;">
            <div class="game newGames">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Safari Gold</div>
                    </div>
                    <div class="image-cover image-cover4"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61ee5195e4d14.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61ee5195e4d14.jpg.webp" lazy="loaded"></div>
                    <!---->
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>99</span></div>
                    </div>
                </div>
            </div>
            <div class="game newGames">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Mighty Buffalo</div>
                    </div>
                    <div class="image-cover image-cover4"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4cc98ffbe.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4cc98ffbe.jpg.webp" lazy="loaded"></div>
                    <!---->
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>576</span></div>
                    </div>
                </div>
            </div>
            <div class="game newGames">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Football Carnival</div>
                    </div>
                    <div class="image-cover image-cover4"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4ca3007ca.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4ca3007ca.jpg.webp" lazy="loaded"></div>
                    <!---->
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>1506</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="itemTitleBox">
            <div class="left" style="display: flex; justify-content: center; align-items: center;">
                <div class="itemIcon3"></div>
                <div class="itemTitle">LIVES</div>
            </div>
            <div class="right" style="display: flex; justify-content: center; align-items: center;"><span>More</span>
                <div class="icon"></div>
            </div>
        </div>
        <div class="topGame" style="display: flex; justify-content: space-between; align-items: center;">
            <div class="game newGames">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Super Champions</div>
                    </div>
                    <div class="image-cover image-cover3"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4cda97277.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4cda97277.jpg.webp" lazy="loaded"></div>
                    <!---->
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>847</span></div>
                    </div>
                </div>
            </div>
            <div class="game newGames">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Candy Battle</div>
                    </div>
                    <div class="image-cover image-cover2"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4c0a8420b.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4c0a8420b.jpg.webp" lazy="loaded"></div>
                    <!---->
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>146</span></div>
                    </div>
                </div>
            </div>
            <div class="game newGames">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Baccarat</div>
                    </div>
                    <div class="image-cover image-cover3"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4ad6af9ac.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4ad6af9ac.jpg.webp" lazy="loaded"></div>
                    <!---->
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>186</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="itemTitleBox">
            <div class="left" style="display: flex; justify-content: center; align-items: center;">
                <div class="itemIcon5"></div>
                <div class="itemTitle">TABLE GAMES</div>
            </div>
            <div class="right" style="display: flex; justify-content: center; align-items: center;"><span>More</span>
                <div class="icon"></div>
            </div>
        </div>
        <div class="topGame" style="display: flex; justify-content: space-between; align-items: center;">
            <div class="game newGames">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Fruit Slots </div>
                    </div>
                    <div class="image-cover image-cover3"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4bfe40992.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4bfe40992.jpg.webp" lazy="loaded"></div>
                    <!---->
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>1791</span></div>
                    </div>
                </div>
            </div>
            <div class="game newGames">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Zoo</div>
                    </div>
                    <div class="image-cover image-cover2"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4bdd2e63a.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4bdd2e63a.jpg.webp" lazy="loaded"></div>
                    <!---->
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>143</span></div>
                    </div>
                </div>
            </div>
            <div class="game newGames">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Big Wheel </div>
                    </div>
                    <div class="image-cover image-cover2"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4b5b3ba75.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4b5b3ba75.jpg.webp" lazy="loaded"></div>
                    <!---->
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>1119</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="itemTitleBox">
            <div class="left" style="display: flex; justify-content: center; align-items: center;">
                <div class="itemIcon4"></div>
                <div class="itemTitle">FOOTBALL SLOTS</div>
            </div>
            <div class="right" style="display: flex; justify-content: center; align-items: center;"><span>More</span>
                <div class="icon"></div>
            </div>
        </div>
        <div class="topGame" style="display: flex; justify-content: space-between; align-items: center;">
            <div class="game newGames">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Africa cup </div>
                    </div>
                    <div class="image-cover image-cover2"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4a93df018.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4a93df018.jpg.webp" lazy="loaded"></div>
                    <!---->
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>587</span></div>
                    </div>
                </div>
            </div>
            <div class="game newGames">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Premier League 2020</div>
                    </div>
                    <div class="image-cover image-cover2"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4b097ca12.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4b097ca12.jpg.webp" lazy="loaded"></div>
                    <!---->
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>1643</span></div>
                    </div>
                </div>
            </div>
            <div class="game newGames">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Bahati King</div>
                    </div>
                    <div class="image-cover image-cover3"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4b9262e82.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4b9262e82.jpg.webp" lazy="loaded"></div>
                    <!---->
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>874</span></div>
                    </div>
                </div>
            </div>
            <div class="game newGames">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Football Carnival</div>
                    </div>
                    <div class="image-cover image-cover4"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4ca3007ca.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4ca3007ca.jpg.webp" lazy="loaded"></div>
                    <!---->
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>1506</span></div>
                    </div>
                </div>
            </div>
            <div class="game newGames">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Premier League Star</div>
                    </div>
                    <div class="image-cover image-cover4"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4c95de532.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4c95de532.jpg.webp" lazy="loaded"></div>
                    <!---->
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>549</span></div>
                    </div>
                </div>
            </div>
            <div class="game newGames">
                <div class="imgBox">
                    <div class="img-shadow">
                        <div class="name">Hot Spin</div>
                    </div>
                    <div class="image-cover image-cover3"><img alt="" data-src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4c73a6638.jpg.webp" src="https://d38w4tjsz5fa1a.cloudfront.net/display/61dd4c73a6638.jpg.webp" lazy="loaded"></div>
                    <!---->
                </div>
                <div class="peopleNumber">
                    <div class="peopleNumber-icon"></div>
                    <div class="peopleNumber-right">
                        <div class="peopleNumber-number"><span>701</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="lottoBanner"></div>
</div>
</template>

<script>
export default {
    name: 'GameBox',
    methods: {
        playGame() {
            this.$router.push({ name: 'Play'});
        }
    }    
}
</script>

<style lang="scss" scoped>
.gameBox {
    padding: 0.18518519rem 0 0;

    .itemTitleBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: .36111111rem;
        color: #fff;
        font-weight: 700;
        padding: 0 0.27777778rem 0.05555556rem;

        .itemTitle {
            padding-left: 0.11111111rem;
        }

        .right {
            font-weight: 400;
            font-size: .25rem;

            .icon {
                background-image: url(../assets/icon.png);
                background-position: -4.5462963rem -1.30555556rem;
                width: 0.22222222rem;
                height: 0.22222222rem;
                background-size: 5.66666667rem 5.37037037rem;
                margin-left: 0.11111111rem;
            }
        }

        .itemIcon1,
        .itemIcon2,
        .itemIcon3,
        .itemIcon4,
        .itemIcon5 {
            background-image: url(../assets/icon.png);
            width: 0.33333333rem;
            height: 0.33333333rem;
            background-size: 5.66666667rem 5.37037037rem;
        }

        .itemIcon1 {
            background-position: -4.55555556rem -4.12962963rem;
        }

        .itemIcon2 {
            background-position: -2.07407407rem -4.88888889rem;
        }

        .itemIcon3 {
            background-position: -1.64814815rem -4.88888889rem;
        }

        .itemIcon4 {
            background-position: -2.5rem -4.88888889rem;
        }

        .itemIcon5 {
            background-position: -2.92592593rem -4.88888889rem;
        }

    }

    .topGame {
        flex-wrap: wrap;
        padding: 0 0.27777778rem 0.33333333rem;

        .imgBox {
            position: relative;

            .img-shadow {
                position: absolute;
                left: 0;
                top: 1.86111111rem;
                width: 100%;
                height: 0.44444444rem;
                background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .4));
                border-radius: 0 0 0.08333333rem 0.08333333rem;

                .name {
                    font-size: .30555556rem;
                    color: #fff;
                    overflow: hidden;
                    text-transform: uppercase;
                    margin-left: 0.13888889rem;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .peopleNumber {
            overflow: hidden;
            width: 1.11111111rem;
            height: 0.33333333rem;
            border-radius: 0.16666667rem;
            box-sizing: border-box;
            padding-left: 0.08333333rem;
            background-color: rgba(0, 0, 0, .4);
            position: absolute;
            left: 0.11111111rem;
            top: 0.08333333rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .peopleNumber-icon {
                background-image: url(../assets/icon.png);
                background-position: -4.22222222rem -1.30555556rem;
                width: 0.23148148rem;
                height: 0.23148148rem;
                background-size: 5.66666667rem 5.37037037rem;
            }

            .peopleNumber-right {
                flex: 1;
            }

            .peopleNumber-number {
                width: 100%;
                text-align: center;
                color: #fff;
                font-size: .25rem;
            }
        }

    }

    .game {
        position: relative;
        width: 4.62962963rem;
        margin-top: 0.17592593rem;

        .image-cover {
            width: 4.63888889rem;
            height: 2.30555556rem;
            border-radius: 0.08333333rem;
            overflow: hidden;

        }

        .image-cover.image-cover4 {
            background: linear-gradient(180deg, #95f1bb, #5cde85);
        }

        .button {
            margin-top: 0.13888889rem;
            color: #fff;

            .play {
                width: 2.83333333rem;
                height: 0.58333333rem;
                border-radius: 0.27777778rem;
                font-weight: 900;
                background: linear-gradient(90deg, #e601fe, #7902ff);
            }

            .demo {
                width: 1.72222222rem;
                height: 0.58333333rem;
                border-radius: 0.27777778rem;
                background: #2a2b40;
                margin-left: 0.09259259rem;
            }
        }

        img {
            width: 4.63888889rem;
            height: 2.30555556rem;
            border-radius: 0.08333333rem;
            overflow: hidden;
        }
    }
    
    .game.newGames {
        width: 3.02777778rem;
        .image-cover,
        img {
            width: 3.02777778rem;
            height: 2.30555556rem;
        }
    }
    
    .lottoBanner {
        margin: 0.18518519rem auto 0.33333333rem;
        width: 9.42592593rem;
        height: 1.48148148rem;
        border-radius: 0.09259259rem;
        overflow: hidden;
        background: url(https://d38w4tjsz5fa1a.cloudfront.net/webGamemaniaRelease/static/img/jackpot.7b1d2e0.jpg) no-repeat 50%/cover;
    }
}
</style>
