<template>
<div class="main">
    <div class="hall">
        <transition :name="$store.state.pageTransition.name" :mode="$store.state.pageTransition.mode" v-on:after-enter="afterEnter" v-on:after-leave="afterLeave">
            <router-view />
        </transition>
    </div>
    <div class="footer-wrap">
        <div class="shadow"></div>
        <div class="iconBg"></div>
        <div class="footer-content">
            <div class="item" @click="goMenu('Home')" v-bind:class="{ 'selectItem': this.$route.name == 'Home'}">
                <div class="iconBox">
                    <div class="icon Home">
                        <!---->
                    </div>
                </div>
                <div class="name">Home</div>
            </div>
            <div class="item " @click="goMenu('Deposit')" v-bind:class="{ 'selectItem': this.$route.name == 'Deposit'}">
                <div class="iconBox">
                    <div class="icon Deposit">
                        <!---->
                    </div>
                </div>
                <div class="name">Deposit</div>
            </div>
            <div class="item" @click="goMenu('Promotion')" v-bind:class="{ 'selectItem': this.$route.name == 'Promotion'}">
                <div class="iconBox promotionBox">
                    <div class="icon Promotion">
                        <div class="newIcon"></div>
                    </div>
                </div>
                <div class="name">Promotion</div>
            </div>
            <div class="item" @click="goMenu('Withdraw')" v-bind:class="{ 'selectItem': this.$route.name == 'Withdraw'}">
                <div class="iconBox">
                    <div class="icon Withdraw">
                        <!---->
                    </div>
                </div>
                <div class="name">Withdraw</div>
            </div>
            <div class="item" @click="goMenu('Profile')" v-bind:class="{ 'selectItem': this.$route.name == 'Profile'}">
                <div class="iconBox">
                    <div class="icon Profile">
                        <!---->
                    </div>
                </div>
                <div class="name">Profile</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// @ is an alias to /src
// import TopBanner from '@/components/TopBanner.vue'
import Store from "@/store/index"

export default {
    name: 'Main',
    components: {
        // TopBanner
    },
    props: {
        routeName: String
    },
    methods: {
        afterEnter: () => {
            window.scrollTo(0, 0);
        },
        afterLeave: () => {
            Store.commit("setPageTransition", "default");
        },
        goMenu(name) {
          if (this.$route.name !== name) {
            var route = name == 'Home' ? '/' : name;
            this.$router.push(route);
          }
        }
    }
}
</script>

<style lang="scss">
.hall {
    width: 10rem;
    height: 100%;
    background: #1e1f2d;
}

.main {
    height: 100%;
}

.footer-wrap {
    position: fixed;
    bottom: 0;
    width: 10rem;
    height: 1.30555556rem;
    border-top: 1px solid #474959;
    color: hsla(0, 0%, 100%, .3);
    z-index: 15;

    .shadow {
        position: absolute;
        pointer-events: none;
        bottom: 1.2962963rem;
        width: 10rem;
        box-shadow: 0 0 0.75rem 0.27777778rem rgb(0 0 0 / 25%);
    }

    .iconBg {
        width: 1.38888889rem;
        height: 1.38888889rem;
        border-radius: 50%;
        background-color: #2a2b40;
        position: absolute;
        left: 4.27777778rem;
        top: -0.36111111rem;
        z-index: -1;
        border: 0.02777778rem solid #474959;
    }

    .footer-content {
        position: relative;
        background-color: #2a2b40;
        z-index: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 1.30555556rem;

        .item {
            flex: 1;
            text-align: center;
            font-size: .25rem;         
            .icon {
                position: relative;
                margin: 0 auto;
                .newIcon {
                    position: absolute;
                    right: -0.41666667rem;
                    top: -0.13888889rem;
                    background-image: url(../assets/footer.png);
                    background-position: -3.59722222rem -1.19444444rem;
                    width: 0.66666667rem;
                    height: 0.31944444rem;
                    background-size: 4.65277778rem 3.44444444rem;
                }
            }
            .name {
                padding-bottom: 0.08333333rem;
            }
            .Home, .Deposit, .Promotion, .Withdraw, .Profile {
              background-image: url(../assets/footer.png);
              width: 0.63333333rem;
              height: 0.63333333rem;                 
              background-size: 2.79166667rem 2.06666667rem;
            }
            .Home {
              background-position: -0.71666667rem -0.71666667rem;
            }
            .Deposit {
              background-position: 0 -0.71666667rem;
            }
            .promotionBox {
                background-color: #1e1f2d;
                width: 1.11111111rem;
                height: 1.11111111rem;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                z-index: 1;
                top: -0.22222222rem;
                left: 4.44444444rem;
            }
            .Promotion {
                background-position: -0.71666667rem -1.43333333rem;
            }
            .Withdraw {
                background-position: -2.15833333rem 0;
            }
            .Profile {
                background-position: -1.44166667rem -0.71666667rem;
            }
        }

        .item.selectItem {
            color: #cb01fe;
            .Home {
              background-position: 0 0;
            }
            .Deposit {
              background-position: -0.725rem 0;
            }
            .Promotion {
              background-position: 0 -1.43333333rem;
            }
            .Withdraw {
              background-position:-1.43333333rem -1.43333333rem;
            }
            .Profile {
              background-position:-1.44166667rem 0;
            }
        }
    }

    
}




</style>
