import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Deposit from '../views/Deposit.vue'
import Withdraw from '../views/Withdraw.vue'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    children: [
      {path: '/', name: 'Home', component: Home},
      {path: '/deposit', name: 'Deposit', component: Deposit},
      {path: '/promotion', name: 'Promotion', component: () => import(/* webpackChunkName: "promotion" */ '../views/Promotion.vue')},
      {path: '/withdraw', name: 'Withdraw', component: Withdraw},
      {path: '/profile', name: 'Profile', component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')},
      {path: '/play', name: 'Play', component: () => import(/* webpackChunkName: "play" */ '../views/Play.vue')},
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login', component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
